import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Success extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title="Contact Success"
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Thank You</h1>
              </header>
              <p>Thank you for contacting us!</p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Success

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
